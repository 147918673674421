export default {
    refresh: {
        text1: "Drag down to referesh...",
        text2: "Release to referesh...",
        text3: "Loading...",
        text4: "Successfully refreshed",
    },
    noMore: "noMore~",
    home: {},
    public: {
        copy_success: "Copy succeeded",
        copy_success1: "URL copied, paste it in your browser to access!",
        button: {
            text: "Confirm",
            jump: "Skip",
            text_ok: "Confirm",
            text_ok1: "OK",
            cancel: "Cancel",
        },
        networkError: {
            text1: "Error, please try again!",
            text2: "Login expired or error occurred, please login again!",
            text3: "The requested resource does not exist!",
            mail: "Please open it in the Supe App!",
            errorProcess: "You are not in the App environment, please open the Supe App to visit!",
            errorMethodsNotDefine: "The method does not exist!",
        },
    },
    error_code: {
        1400500: "Already filled in the invitation code",
        1400501: "The invitation code does not exist",
        1400502: "own invitation code",
        1400503: "Can't invite each other",
    },
};
