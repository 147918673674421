import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
const debug = process.env.NODE_ENV !== "production";

const state = {
    lang: "en-US",
    loveLoading: false,
    userInfo: {},
    // inviteInfo: {}, // 存储邀请人
    userInviteCode: "",
    isUserLoggedIn: false,
    symbol: "", //币种
    count: "0", //数量
    points: "0", //积分数量
    maxPoints: "0", //积分最大兑换数量
    minPoints: "0", //积分最小兑换数量
    rate: 0, //兑换比例
};

const mutations = {
    updateLang(state, value) {
        state.lang = value;
    },
    updateLoveLoading(state, value) {
        state.loveLoading = value;
    },
    // // 存储邀请人
    // updateInviteInfo(state, value) {
    //     state.inviteInfo = value;
    // },
    updateUserInviteCode(state, value) {
        state.userInviteCode = value;
    },
    updateUserInfo(state, value) {
        state.userInfo = value;
    },
    updateIsUserLoggedIn(state, value) {
        state.isUserLoggedIn = value;
    },
    updateSymbol(state, value) {
        state.symbol = value;
    },
    updateCount(state, value) {
        state.count = value;
    },
    updatePoints(state, value) {
        state.points = value;
    },
    updateMaxPoints(state, value) {
        state.maxPoints = value;
    },
    updateMinPoints(state, value) {
        state.minPoints = value;
    },
    updateRate(state, value) {
        state.rate = value;
    },
};
const actions = {};

export default createStore({
    state,
    mutations,
    actions,
    strict: debug,
    plugins: [createPersistedState()],
});
