import { myVanToast } from "@/plugins/vant.js";
import i18n from "@/plugins/i18n.js";

export const copyText = (item, type) => {
    var input = document.createElement("textarea"); // js创建一个input输入框
    input.value = item; // 将需要复制的文本赋值到创建的input输入框中
    document.body.appendChild(input); // 将输入框暂时创建到实例里面
    input.select(); // 选中输入框中的内容
    document.execCommand("Copy"); // 执行复制操作
    document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
    type
        ? null
        : myVanToast({
              type: "success",
              message: i18n.global.t("public.copy_success"),
              duration: 3000,
              position: "top",
              iconStatus: 2,
          });
};

export const isH5 = () => {
    let sUserAgent = navigator.userAgent.toLowerCase();
    let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    let bIsMidp = sUserAgent.match(/midp/i) == "midp";
    let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    let bIsUcBrowser = sUserAgent.match(/ucbrowser/i) == "ucbrowser";
    let bIsAndroid = sUserAgent.match(/android/i) == "android";
    let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";

    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM || bIsUcBrowser) {
        return true;
    } else {
        return false;
    }
};

export const getRequest = () => {
    var a = decodeURI(location.href).split("&last")[0]; //获取url中"?"符后的字串
    var url = "?" + a.split("?")[1];
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = [];
        strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
};

export const getCookie = (cname) => {
    var aCookie = document.cookie.split("; ");
    for (var i = 0; i < aCookie.length; i++) {
        var aCrumb = aCookie[i].split("=");
        if (cname === aCrumb[0]) return aCrumb[1];
    }
    return "";
};

export const setCookie = function (name, value) {
    //cookie过期时间1年
    var Days = 365;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    var cookieText = encodeURIComponent(name) + "=" + encodeURIComponent(value) + "; expires=" + exp.toGMTString() + "; path=/";
    document.cookie = cookieText;
};
// export const setCookie = (cname, cvalue) => {
//     var cookieText = encodeURIComponent(cname) + "=" + encodeURIComponent(cvalue) + "; path=/";
//     document.cookie = cookieText;
// };

export const formatAddress = (address, s = 10, e = 4) => {
    if (address) {
        const prefix = address.substring(0, s);
        const suffix = address.substring(address.length - e);
        return `${prefix}****${suffix}`;
    } else {
        return `...`;
    }
};

function add0(m) {
    return m < 10 ? "0" + m : m;
}
export const format = (time) => {
    //shijianchuo是整数，否则要parseInt转换
    if (time == "" || time == 0) {
        return "...";
    } else {
        var time2 = new Date(time * 1000);
        var y = time2.getFullYear();
        var m = time2.getMonth() + 1;
        var d = time2.getDate();
        var h = time2.getHours();
        var mm = time2.getMinutes();
        var s = time2.getSeconds();
        return y + "." + add0(m) + "." + add0(d) + " " + add0(h) + ":" + add0(mm) + ":" + add0(s);
    }
};
export const format1 = (time) => {
    //shijianchuo是整数，否则要parseInt转换
    if (time == "" || time == 0) {
        return "...";
    } else {
        var time2 = new Date(time * 1000);
        var y = time2.getFullYear();
        var m = time2.getMonth() + 1;
        var d = time2.getDate();
        // var h = time2.getHours();
        // var mm = time2.getMinutes();
        // var s = time2.getSeconds();
        return y + "." + add0(m) + "." + add0(d);
    }
};
