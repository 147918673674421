export default {
    refresh: {
        text1: "下拉即可刷新...",
        text2: "释放即可刷新...",
        text3: "加载中...",
        text4: "刷新成功",
    },
    noMore: "没有更多了~",
    home: {},
    public: {
        copy_success: "复制成功",
        copy_success1: "网址已复制，请前往浏览器粘贴即可访问！",
        button: {
            text: "确定",
            jump: "跳过",
            text_ok: "确认",
            text_ok1: "好的",
            cancel: "取消",
        },
        networkError: {
            text1: "错误，请重试！",
            text2: "登录过期或出现错误，请重新登录！",
            text3: "请求的资源不存在！",
            mail: "请在Supe App内打开!",
            errorProcess: "您不在App环境内，请打开Supe App访问！",
            errorMethodsNotDefine: "方法不存在!",
        },
    },
    error_code: {
        1400500: "Already filled in the invitation code",
        1400501: "The invitation code does not exist",
        1400502: "own invitation code",
        1400503: "Can't invite each other",
    },
};
