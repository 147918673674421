const Home = () => import("@/views/Home");
const Loading = () => import("@/views/Loading");
const Main = () => import("@/views/Main");
const routes = [
    {
        path: "/",
        redirect: {
            name: "home",
        },
    },
    {
        path: "/home",
        name: "home",
        component: Home,
    },
    {
        path: "/loading",
        name: "loading",
        component: Loading,
    },
    {
        path: "/main",
        name: "main",
        component: Main,
    },
];
export default routes;
